import {
  useEffect, useState, lazy, Suspense,
} from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Loading from 'components/system/Loading';
import { MobileCover } from 'components/pages/Layout';
import customTheme from 'styles/theme';
import 'styles/globalStyles.scss';
import HelmetContainer from './HelmetContainer';

const MainApp = lazy(() => import('./MainApp'));

const App = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(/Mobi|Android|iPhone/i.test(navigator.userAgent));
  }, []);

  return (
    <ThemeProvider theme={customTheme}>
      <HelmetContainer />
      {
        isMobile ? (
          <MobileCover />
        ) : (
          <Suspense fallback={<Loading />}>
            <MainApp />
          </Suspense>
        )
      }
    </ThemeProvider>
  );
};

export default App;
