import { useState } from 'react';
import { useMutation } from 'react-query';
import { stopNotebookServer } from 'api/services/notebook';
import { auth, useAuthState } from 'api/services/firebase';
import useAccountStore from '../store/accountStore';

const useNotebookHostKill = () => {
  const [retryCount, setRetryCount] = useState(0);
  const [user] = useAuthState(auth);
  const isUserAvailable = !!user;
  const [stopError, setStopError] = useState(null);

  const setActiveNotebook = useAccountStore(
    (state) => state.setActiveNotebook,
  );

  const notebookUrl = useAccountStore(
    (state) => state.account.notebookUrl,
  );

  const mutation = useMutation(stopNotebookServer, {
    onMutate: () => {
      setStopError(null);
    },
    onError: (error) => {
      setRetryCount((prevCount) => {
        const newCount = prevCount + 1;
        if (newCount < 5) {
          mutation.mutate();
        } else if (!stopError) {
          setStopError(new Error('Failed to stop the server.'));
        }
        return newCount;
      });
    },
    onSuccess: () => {
      setTimeout(() => {
        setActiveNotebook(null, false);
      }, 3_000);
    },
  });

  const stopServer = () => {
    if (isUserAvailable && notebookUrl) {
      mutation.mutate();
    }
  };

  return {
    data: mutation.data || false,
    isStopping: mutation.isLoading,
    error: mutation.error,
    stopError,
    stopServer,
  };
};

export default useNotebookHostKill;
