import Typography from '@mui/material/Typography';
import ConfirmationDialog from 'components/system/ConfirmationDialog';

const JobAutoShutdownStopConfirmation = ({ onConfirm, onCancel, onClose }) => (
  <ConfirmationDialog
    title="Confirm Auto Stop Disable"
    onCancel={onCancel}
    onClose={onClose}
    onConfirm={onConfirm}
    confirmText="Disable Auto Stop"
    reversed
  >
    <Typography variant="body2">
      Are you sure you want disable Auto Stop? This will keep your machine ON and incur charges till you manually
      stop it
    </Typography>
  </ConfirmationDialog>
);

export default JobAutoShutdownStopConfirmation;
