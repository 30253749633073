import { useMutation, useQueryClient } from 'react-query';
import { updateNotebookAutoDisableState } from 'api/services/notebook';

const useUpdateNotebookAutoDisableState = (onSuccess, onError) => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: async (state) => {
      await updateNotebookAutoDisableState({ isOn: state });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['notebookAutoDisableState'] });
      onSuccess();
    },
    onError: (error) => {
      console.log(error);
      onError(error?.errorMessage);
    },
  });

  return {
    mutate,
  };
};

export default useUpdateNotebookAutoDisableState;
