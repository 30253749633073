import { useQuery } from 'react-query';
import { getNotebookAutoDisableState } from 'api/services/notebook';
import useAccountStore from '../store/accountStore';

const useNotebookAutoDisableState = () => {
  const setNotebookAutoDisableState = useAccountStore(
    (state) => state.setNotebookAutoDisableState,
  );

  useQuery({
    queryKey: 'notebookAutoDisableState',
    queryFn: getNotebookAutoDisableState,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    retry: false,
    onSettled: (response, err) => {
      if (!err) {
        setNotebookAutoDisableState(response.isOn);
      }
    },
  });
};

export default useNotebookAutoDisableState;
