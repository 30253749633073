import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import useNotebookHostKill from 'hooks/useNotebookHostKill';
import useAccountStore from 'store/accountStore';
import { useModalContext } from 'components/system/ModalContext';
import JobNotebookStopConfirmation from 'components/pages/JobNotebook/JobNotebookStopConfirmation';
import { toast } from 'react-toastify';
import Checkbox from 'components/system/Checkbox/Checkbox';
import JobAutoShutdownStopConfirmation from 'components/pages/JobNotebook/JobAutoShutdownStopConfirmation';
import useNotebookAutoDisableState from 'hooks/useNotebookAutoDisbaleState';
import useUpdateNotebookAutoDisableState from 'hooks/useUpdateNotebookAutoDisableState';
import withNotification from '../../hoc/withNotification';

const NotebookSection = ({
  showSuccessNotification,
  showInfoNotification,
  showErrorNotification,
}) => {
  const [isAutoDisableOn, setIsAutoDisabledOn] = useState(false);
  const [stoppingToasterId, setStoppingToasterId] = useState(null);

  const {
    data: isStopped,
    isStopping,
    stopError,
    stopServer,
  } = useNotebookHostKill();

  const { openModal, closeModal } = useModalContext();

  const { notebookUrl, isNotebookKilling, autoDisableState } = useAccountStore(
    (state) => ({
      notebookUrl: state.account.notebookUrl,
      isNotebookKilling: state.account.isNotebookKilling,
      autoDisableState: state.account.autoDisableState,
    }),
  );

  const setActiveNotebookIsKilling = useAccountStore(
    (state) => state.setActiveNotebookIsKilling,
  );

  const handleStopNotebook = () => {
    if (!isNotebookKilling && !isStopping) {
      setActiveNotebookIsKilling(true);
      stopServer();
    }
  };

  const handleAutoShutDownError = (error) => {
    closeModal();
    showErrorNotification(
      error,
      undefined,
      2000,
    );
  };

  const { mutate } = useUpdateNotebookAutoDisableState(closeModal, handleAutoShutDownError);
  useNotebookAutoDisableState();

  const handleAutoShutDown = (state) => {
    mutate(state);
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      handleAutoShutDown(event.target.checked);
    } else {
      openModal({
        content: (
          <JobAutoShutdownStopConfirmation
            onCancel={closeModal}
            onConfirm={() => handleAutoShutDown(false)}
          />
        ),
      });
    }
  };

  useEffect(() => {
    setIsAutoDisabledOn(autoDisableState);
  }, [autoDisableState]);

  useEffect(() => {
    if (isStopped && !isStopping && !stopError) {
      // Notebook server stopped successfully
      setActiveNotebookIsKilling(false);
      if (stoppingToasterId) toast.dismiss(stoppingToasterId);
      showSuccessNotification('The notebook server is stopped successfully.');
    } else if (!isStopped && !isStopping && stopError) {
      // Notebook server stop failed
      setActiveNotebookIsKilling(false);
      if (stoppingToasterId) toast.dismiss(stoppingToasterId);
      showErrorNotification('Failed to stop the notebook server. Please try again.');
    }
  }, [isStopped, stopError, isStopping]);

  useEffect(() => {
    if (isStopping && !stoppingToasterId) {
      const toasterId = showInfoNotification('Stopping the machine...', undefined, {
        onClose: () => setStoppingToasterId(null),
      });
      setStoppingToasterId(toasterId);
    }
  }, [isStopping, stoppingToasterId]);

  const handleStop = () => {
    openModal({
      content: (
        <JobNotebookStopConfirmation
          onCancel={closeModal}
          onClose={closeModal}
          onConfirm={handleStopNotebook}
        />
      ),
    });
  };

  return (
    <Grid item sx={{ paddingLeft: '24px', display: 'flex' }}>
      <Button
        disabled={!notebookUrl || isNotebookKilling || isStopping}
        type="secondary"
        variant="outlined"
        onClick={handleStop}
        sx={{ padding: '10px 20px', fontSize: '14px', marginRight: '24px' }}
      >
        Stop
      </Button>
      {notebookUrl && !isNotebookKilling
        ? (
          <Checkbox
            label="Auto Stop"
            labelPlacement="right"
            tooltip={isAutoDisableOn ? 'The machine will be turned off after 2 hours of inactivity'
              : 'Auto Stop is disabled. The notebook will stay active indefinitely, which may consume more resources.'}
            checked={isAutoDisableOn}
            onChange={handleCheckboxChange}
          />
        ) : null}
    </Grid>
  );
};

export default withNotification(NotebookSection);
