import * as React from 'react';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import colors from 'styles/colors';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const DEFAULT_LABEL_PLACEMENT = 'right';

const Icon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 18,
  height: 18,
  backgroundColor: '#fff',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: colors.primary.light,
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
}));

const CheckedIcon = styled(Icon)({
  backgroundColor: colors.primary.main,
  '&::before': {
    display: 'block',
    width: 18,
    height: 18,
    backgroundImage:
      'url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' width=\'18\' height=\'18\'%3E%3Cpath'
      + ' fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 '
      + '1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z\' fill=\'%23fff\'/%3E%3C/svg%3E")',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: colors.primary.light,
  },
});

const CustomCheckbox = (props) => (
  <Checkbox
    sx={{
      '&:hover': { bgcolor: 'transparent' },
      padding: '0 8px 0 0',
    }}
    disableRipple
    color="default"
    checkedIcon={<CheckedIcon />}
    icon={<Icon />}
    inputProps={{ 'aria-label': 'Checkbox' }}
    {...props}
  />
);

const getFlexDirection = (labelPlacement) => (labelPlacement === 'top' || labelPlacement === 'bottom' ? 'column' : 'row');

const renderAlignedLabelCheckbox = (labelPlacement, renderLabel, checkboxElement) => {
  if (labelPlacement === 'left') {
    return (
      <>
        {renderLabel()}
        {checkboxElement}
      </>
    );
  }
  if (labelPlacement === 'right') {
    return (
      <>
        {checkboxElement}
        {renderLabel()}
      </>
    );
  }
  // For top or bottom placement
  return (
    <>
      {checkboxElement}
      {renderLabel()}
    </>
  );
};

const CustomizedCheckbox = ({
  label,
  labelPlacement = DEFAULT_LABEL_PLACEMENT,
  checked,
  onChange,
  tooltip = '',
}) => {
  const renderLabel = () => (
    <Typography color={colors.text.primary}>
      {label}
    </Typography>
  );

  const checkboxElement = <CustomCheckbox checked={checked} onChange={onChange} />;

  const content = (
    <Box
      display="flex"
      alignItems="center"
      flexDirection={getFlexDirection(labelPlacement)}
    >
      {renderAlignedLabelCheckbox(labelPlacement, renderLabel, checkboxElement)}
    </Box>
  );

  return tooltip ? (
    <Tooltip title={tooltip} arrow>
      {content}
    </Tooltip>
  ) : content;
};

export default CustomizedCheckbox;
