import axios from '../axios';

export const getAccountData = () => async () => {
  try {
    const { data: { data } } = await axios.get('/users/me');
    return data;
  } catch (err) {
    console.log('An error occurred while fetching account data', err.message);
    throw err;
  }
};

export const deleteAccount = async () => {
  try {
    const response = await axios.patch('/users/me');
    return response;
  } catch (err) {
    console.log('An error occurred while deleting account data', err.message);
    throw err;
  }
};

export const uploadAvatar = async (croppedImage) => {
  try {
    const response = await axios.post(
      '/users/me/avatar',
      { avatar: croppedImage },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    console.log('Avatar uploaded successfully:', response.data);
  } catch (error) {
    console.error('Error uploading avatar:', error);
  }
};

export const removeAvatar = async () => {
  try {
    const response = await axios.delete('/users/me/avatar');
    console.log('Avatar removed successfully:', response.data);
  } catch (error) {
    console.error('Error removing avatar:', error);
  }
};
